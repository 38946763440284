<template>
  <div class="layout">
    <nav-bar
      :logoSrc="$maxxpm.config.logo.src"
      :logoAlt="$maxxpm.config.logo.alt"
      :bannerTitle="$maxxpm.config.nav.bannerTitle"
      :bannerSubtitle="$maxxpm.config.nav.bannerSubtitle"
      :bannerCTA="$maxxpm.config.nav.bannerCTA"
    />
    <main>
      <router-view />
      <Dialog
        v-model:visible="showPopup"
        :modal="true"
        :dismissableMask="true"
        :style="{ width: '50vw' }"
        :breakpoints="{ '960px': '75vw', '641px': '95vw' }"
      >
        <template #header>
          <h2 class="text-center flex-grow-1">
            Don't wait, schedule to visit the property today!
          </h2>
        </template>
        <div class="flex justify-content-center p-4 popup-showing">
          <Button
            @click="
              () => {
                Calendly.initPopupWidget({
                  url: $maxxpm.config.calendlyLink,
                });
              }
            "
            aria-haspopup="dialog"
            label="Schedule a Showing"
          ></Button>
        </div>
      </Dialog>
    </main>
    <footer>
      <app-footer
        :communityName="$maxxpm.config.communityName"
        :streetAddress="$maxxpm.config.footer.address"
        :cityStateZip="$maxxpm.config.footer.cityStateZip"
        :phone="$maxxpm.config.footer.phone"
        :logoUrl="$maxxpm.config.footer.logo ?? $maxxpm.config.logo.src"
        :copyrightYear="$maxxpm.config.footer.copyrightYear"
      />
    </footer>
  </div>
</template>

<script setup>
import { inject, ref, onMounted } from "vue";
import { event } from "vue-gtag";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

const $maxxpm = inject("$maxxpm");

const showPopup = ref(false);
const Calendly = ref(null);

function isCalendlyEvent(e) {
  return (
    e.origin === "https://calendly.com" &&
    e.data.event &&
    e.data.event.indexOf("calendly.") === 0
  );
}

function calendlyMessageHandler(e) {
  if (isCalendlyEvent(e)) {
    switch (e.data.event) {
      case "calendly.event_type_viewed":
        event("agent_showing_viewed", {
          event_category: "tenants",
          event_label: "Home page popup",
        });
        break;
      case "calendly.date_and_time_selected":
        event("agent_showing_date_time_selected", {
          event_category: "tenants",
          event_label: "Home page popup",
        });
        break;
      case "calendly.event_scheduled":
        event("agent_showing_booked", {
          event_category: "tenants",
          event_label: e.data.payload,
        });
        break;
    }
  }
}

const calendlyObserver = new MutationObserver((mutationList) => {
  for (const mutation of mutationList) {
    if (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0) {
      const added = Array.from(mutation.addedNodes);
      const removed = Array.from(mutation.removedNodes);
      if (
        added.length > 0 &&
        added.some(
          (node) =>
            typeof node.classList !== "undefined" &&
            node.classList.contains("calendly-overlay")
        )
      ) {
        window.addEventListener("message", calendlyMessageHandler);
      }

      if (
        removed.length > 0 &&
        removed.some(
          (node) =>
            typeof node.classList !== "undefined" &&
            node.classList.contains("calendly-overlay")
        )
      ) {
        window.removeEventListener("message", calendlyMessageHandler);
        calendlyObserver.disconnect();
      }
    }
  }
});

onMounted(() => {
  Calendly.value = window.Calendly;
  const cname = "showPopup";
  setTimeout(() => {
    if (
      $maxxpm.config.hasCalendlyPopup &&
      !document.cookie.split("; ").find((c) => c.startsWith(cname))
    ) {
      calendlyObserver.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      document.cookie = `${cname}=true; max-age=${60 * 60 * 24 * 1}`;
      showPopup.value = true;
    }
  }, 5000);
});
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}

body {
  margin: 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
</style>
