import { createApp } from "vue";
import PrimeVue from "primevue/config";
import VueGtag from "vue-gtag";
import router from "./router";
import App from "./App.vue";
import { NavBar, Footer } from "@maxxpm/vue-components";
import config from "./config/index";

// import "primevue/resources/themes/lara-light-teal/theme.css"; //theme
import "../src/assets/themes/mytheme/theme.scss"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeflex/primeflex.css"; //flex
import "primeicons/primeicons.css"; //icons

const app = createApp(App);

app.use(PrimeVue);

app.component("nav-bar", NavBar);
app.component("app-footer", Footer);
app.provide("$maxxpm", {
  config,
});

if (config.gtagId) {
  app.use(VueGtag, {
    appName: `${config.communityName} Website`,
    pageTrackerScreenviewEnabled: true,
    config: {
      id: config.gtagId,
    },
    router,
  });
}

app.use(router).mount("#app");

// function scale(color, adjustment) {
//   color = color.replace("#", "");
//   if (color.length === 6) {
//     const decimalColor = parseInt(color, 16);

//     let r = (decimalColor >> 16) + adjustment;
//     r > 255 && (r = 255);
//     r < 0 && (r = 0);

//     let g = (decimalColor & 0x0000ff) + adjustment;
//     g > 255 && (g = 255);
//     g < 0 && (g = 0);

//     let b = ((decimalColor >> 8) & 0x00ff) + adjustment;
//     b > 255 && (g = 255);
//     b < 0 && (g = 0);

//     return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
//   }
// }

// function setCssVar(variable, value) {
//   document.documentElement.style.setProperty(variable, value);
// }
