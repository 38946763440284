import { createRouter, createWebHistory } from "vue-router";
import { nextTick } from "vue";
import config from "../config/index";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/availability",
    name: "Availability",
    component: () =>
      import(
        /* webpackChunkName: "availability" */ "../views/Availability.vue"
      ),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import(/* webpackChunkName: "faq" */ "../views/FAQ.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const nav = document.querySelector("#nav");
      return {
        el: to.hash,
        top: nav.scrollHeight,
        behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to) => {
  nextTick(() => {
    document.title = `${config.communityName} - ${to.name}`;
  });
});

export default router;
